@import url("https://fonts.googleapis.com/css2?family=Kalam&family=Signika+Negative&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Special+Elite&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans+Narrow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jura:wght@500&display=swap");
.App {
  text-align: center;
  background-color: rgb(98, 177, 212);
  height: 45%;
  margin: 20px;
}
.name {
  font-family: "Kalam", cursive;
  font-size: 100px;
  color: rgb(255, 255, 255);
}

.static-text {
  font-size: 50px;
  color: rgb(255, 255, 255);
}
.dynamic-text {
  font-size: 50px;
  color: rgb(255, 255, 255);
  border-right: 2px solid gray;
  font-family: "Special Elite", cursive;
}
.abt {
  margin: 20px;
}
.about {
  font-size: 100px;
  font-weight: 100;
  color: rgb(175, 170, 170);
  font-family: "PT Sans Narrow", sans-serif;
}
.myself {
  font-size: 35px;
  font-family: "Rajdhani", sans-serif;
}

.skills {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 70px;

  font-family: "PT Sans Narrow", sans-serif;
}

.logos {
  display: flex;
  justify-content: space-evenly;
}

.html {
  height: 180px;
}
.css {
  height: 180px;
}
.javascript {
  margin-top: 20px;
  height: 170px;
}
.react {
  margin-top: 20px;
  height: 150px;
}
.bootstrap {
  margin-top: 20px;
  height: 160px;

  margin-right: 15px;
}
.material-ui {
  margin-top: 20px;
  height: 180px;
}
.carousal {
  margin: 20px;
}

.projects {
  margin: 20px;
  font-size: 50px;
}

.projects li {
  font-family: "Jura", sans-serif;
}

.projects p {
  font-size: 20px;
  margin-left: 70px;
  font-weight: lighter;
  color: rgb(172, 162, 162);
}

.more-about {
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.footer {
  display: flex;
  justify-content: center;
}
.footerImg {
  width: 100%;
}
